import {FieldHelperProps, useField} from 'formik'
import {ComponentProps} from 'react'
import Select from '../Select'
import {getCurrentValue} from '../helpers'
import {SelectOption} from '../types'

type onChangeMulti = (option: SelectOption[]) => void
type onChangeSingle = (option: SelectOption) => void

const normalizedOnChange = (
  helper: FieldHelperProps<unknown>,
  isMulti: boolean
): onChangeMulti | onChangeSingle => {
  if (isMulti) {
    return (option: SelectOption) => {
      helper.setValue(prev => prev.push(option))
    }
  } else {
    return (option: SelectOption) => {
      helper.setValue(option)
    }
  }
}

const SelectFormik = ({
  options,
  name,
  isMulti,
  findCurrentByProperty,
  ...rest
}: ComponentProps<typeof Select>) => {
  const [field, , helper] = useField(name)

  if (!options) return null

  const currValue = getCurrentValue({
    options,
    value: field.value,
    isMulti,
    findCurrentByProperty,
  })

  const onChange = normalizedOnChange(helper, isMulti)
  return (
    <Select onChange={onChange} value={currValue} options={options} {...rest} />
  )
}

export default SelectFormik
