import {useAccount} from '@myadbox/nebula-service-api'
import {useLocation} from '@reach/router'
import {HTMLAttributes, useEffect, useState} from 'react'
import TheLogo from '../TheLogo'
import * as helpers from './helpers'

type Props = HTMLAttributes<HTMLElement>

const FormLayout = ({children, className = ``}: Props) => {
  const {account} = useAccount()
  const {pathname} = useLocation()

  const logo = account?.configuration?.logoUrl
  const name = account?.name

  const [isLoginPage, setIsLoginPage] = useState(false)

  useEffect(() => {
    if (!isLoginPage && helpers.createLoginPageChecker(pathname)()) {
      setIsLoginPage(true)
    }
  }, [isLoginPage, pathname])

  return (
    <div
      data-testid="form-layout"
      className={`
        ${className}
        ${isLoginPage ? `animate-zoom-fade-in` : ``}
        text-ui-800
        before:bg-ui-0/80
        before:backdrop-blur-5
        m-auto
        grid
        w-full
        max-w-[22.5rem]
        gap-8
        p-8
        will-change-auto
        before:absolute
        before:inset-0
        before:z-[-1]
        before:rounded
      `}
    >
      <header className={`flex`}>
        <div
          className={`
            relative
            flex
            shrink-[2]
            basis-[60%]
            pr-14
          `}
        >
          {!account?.configuration?.isWhiteLabelled && (
            <TheLogo.Text
              className={`
              aspect-[2/1]
              h-auto
              w-full
              border-0
              object-contain
            `}
            />
          )}
        </div>

        <div
          className={`
            flex
            basis-[60%]
            items-center
          `}
        >
          {logo && name && (
            <img
              alt={name}
              className={`
                ${isLoginPage ? `animate-fade-in` : ``}
                h-auto
                max-h-[3rem]
                w-full
                border-0
                object-contain
                object-right
            `}
              src={logo}
            />
          )}
        </div>
      </header>
      {children}
    </div>
  )
}
export default FormLayout
