import {FC} from 'react'
import {I18nextProvider} from 'react-i18next'
import i18n from '../../../locales/i18n'
import BackButton from '../../BackButton/BackButton'
import HeaderLayout, {HeaderProps} from '../HeaderLayout/HeaderLayout'
import HelpMenu from '../IndexHeader/HelpMenu'

const FocussedHeader: FC<HeaderProps> = ({
  children,
  startContent = <BackButton path="/assets/" />,
  ...props
}) => {
  return (
    <HeaderLayout
      startContent={startContent}
      endContent={
        <I18nextProvider i18n={i18n}>
          <HelpMenu hideIntercomMessenger={true} />
        </I18nextProvider>
      }
      {...props}
    >
      <div
        className={`
          max-w-full
          self-center
          px-4
          md:px-8
        `}
      >
        {children}
      </div>
    </HeaderLayout>
  )
}

export default FocussedHeader
