import {CheckIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {
  Button,
  Checkbox,
  Input,
  Table,
  TextArea,
} from '@myadbox/gatsby-theme-nebula'
import {NestedSchemaField} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactElement} from 'react'
import {ActionsWrapper, getHtmlFieldType} from '../EditableDataTable/helpers'

const stickyColClasses = `
  sticky
  right-0
  z-20
`

interface Props {
  rows: NestedSchemaField[]
  setRecordId: (id: string) => void
  rowIndex: number
  loading?: boolean
  remove?: () => void
}

const AbsoluteWrapper = ({children}: {children: ReactElement}) => (
  <div
    className={`
      relative
      -my-1.5
      h-[--height-var-1]
    `}
    style={{'--height-var-1': `1.675rem`}}
  >
    <div
      className="absolute z-10
      w-full"
    >
      {children}
    </div>
  </div>
)

export const EditRowForm = ({
  rows,
  setRecordId,
  rowIndex,
  loading,
  remove,
}: Props) => {
  const {t} = useTranslation()
  return (
    <>
      {rows.map(field => (
        <Table.Cell key={field.id}>
          {(getHtmlFieldType(field.type, field.options) === `checkbox` && (
            <Checkbox.Formik
              id={field.id}
              aria-label={field.title}
              name={`rows.${rowIndex}.data.${field.name}`}
            />
          )) ||
            (getHtmlFieldType(field.type, field.options) === `textarea` && (
              <AbsoluteWrapper>
                <TextArea.Formik
                  label={field.title}
                  labelHidden
                  id={field.id}
                  name={`rows.${rowIndex}.data.${field.name}`}
                  scaleErrorMessage
                  variant="sm"
                  rows={1}
                  noResize={false}
                />
              </AbsoluteWrapper>
            )) || (
              <AbsoluteWrapper>
                <Input.Formik
                  variant="sm"
                  label={field.title}
                  labelHidden
                  id={field.id}
                  name={`rows.${rowIndex}.data.${field.name}`}
                  scaleErrorMessage
                  type={getHtmlFieldType(field.type, field.options)}
                />
              </AbsoluteWrapper>
            )}
        </Table.Cell>
      ))}
      <Table.Cell>&nbsp;</Table.Cell>
      <Table.Cell className={stickyColClasses}>
        <ActionsWrapper>
          <Button
            size="xs"
            type="submit"
            slim
            variant="primary"
            loading={loading}
            loadingText={``}
            title={
              loading
                ? t`settings.schemas.editRow.saveBtnLoading`
                : t`settings.schemas.editRow.saveBtn`
            }
          >
            <CheckIcon width={18} />
          </Button>
          <Button
            type="button"
            variant="secondary"
            slim
            size="xs"
            disabled={loading}
            title={t`settings.schemas.editRow.cancelBtn`}
            onClick={() => {
              setRecordId(``)
              remove?.()
            }}
          >
            <XMarkIcon width={18} />
          </Button>
        </ActionsWrapper>
      </Table.Cell>
    </>
  )
}

export default EditRowForm
