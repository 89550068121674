import {Text} from '@myadbox/gatsby-theme-nebula'
import {getSortedFields} from '../../SchemaView/helpers'

const DisplayTable = ({fields}) => {
  const sortedFields = getSortedFields(fields)
  return (
    <table
      className={`
        text-tiny
        bg-ui-100
        rounded-200
        dark:bg-ui-50
        min-w-full
        text-left
    `}
    >
      <thead>
        <tr
          className={`
            dark:border-b-ui-200
            border-b-ui-300
            border-b
          `}
        >
          {sortedFields.map(field => (
            <th
              key={field.id}
              className={`
                dark:text-ui-500
                text-ui-700
                whitespace-nowrap
                px-2
                py-1
                font-light
              `}
            >
              <Text
                variant="caption"
                tag="span"
                className={`dark:!text-ui-300 !text-ui-500 text-[1em] !leading-4`}
              >
                {field.title}
              </Text>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {sortedFields.map(field => (
            <td
              key={field.id}
              className={`
                whitespace-nowrap
                px-2
                py-1
              `}
            >
              <span
                className={`
                  dark:bg-ui-100
                  bg-ui-200
                  px-1
                  text-transparent
                `}
              >
                {field.title}
              </span>
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  )
}

export default DisplayTable
