import {usePluginOptions} from '@myadbox/gatsby-theme-nebula/hooks'
import {noop} from '@myadbox/gatsby-theme-nebula/src/utils/noop'
import {useAccount} from '@myadbox/nebula-service-api'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {useWidgetStyles} from '../../hooks/useWidgetStyles'
import {cloudinaryText} from '../../locales/cloudinary'
import {
  CloudinaryUploadWidget,
  CloudinaryWidgetLib,
  UploadHandlerProps,
  WidgetOptions,
} from '../../types/shared/upload.types'

export interface Props {
  cloudinary?: CloudinaryWidgetLib
  onUploaded(props: UploadHandlerProps): void
  widgetOptions?: Partial<WidgetOptions>
  children: React.ReactNode | React.ReactNode[]
}

/**
 * NOTE:
 * Please make sure to match this value in the value of
 * `limit_reached` key of `locales/en-AU/cloudinary.json` file
 */
const MAX_FILES = 100

export const UploadWidgetContext = createContext(null)
UploadWidgetContext.displayName = `UploadWidgetContext Context`

const defaultWidgetOptions = {
  multiple: false,
  maxFiles: MAX_FILES,
  showPoweredBy: false,
  uploadPrefix: process.env.GATSBY_CLOUDINARY_UPLOAD_PREFIX,
}

export const cloudinaryIframeSelector = `iframe[src*="widget.cloudinary.com"]`

const cleanUp = (): void => {
  const iframe = document.querySelector(cloudinaryIframeSelector)
  iframe && iframe?.parentNode.removeChild(iframe)
}

const cloudinaryWidgetLib =
  typeof window !== `undefined`
    ? (window?.cloudinary as unknown as CloudinaryWidgetLib) // avoid TS confusion with cloudinary-core's global definitions
    : null

const UploadWidgetProvider: FC<Props> = ({
  children,
  cloudinary = cloudinaryWidgetLib,
  widgetOptions = {},
  onUploaded = noop,
}) => {
  const widgetStyles = useWidgetStyles()
  const {cloudName} = usePluginOptions()
  const {language} = useI18next()
  const {account} = useAccount()

  const uploadPreset = account?.configuration?.aiTagsEnabled
    ? process.env.GATSBY_CLOUDINARY_UPLOAD_AI_TAGGING_PRESET
    : process.env.GATSBY_CLOUDINARY_UPLOAD_PRESET

  const [uploadWidget, setUploadWidget] = useState(null)
  const widgetCreationOptions = useMemo(() => {
    return {
      ...defaultWidgetOptions,
      ...widgetOptions,
      ...widgetStyles,
      cloudName,
      language,
      text: cloudinaryText,
      uploadPreset,
    }
  }, [cloudName, language, uploadPreset, widgetStyles, widgetOptions])
  if (uploadWidget) {
    uploadWidget.update(widgetStyles)
  } else if (cloudinary) {
    setUploadWidget(
      cloudinary.createUploadWidget(widgetCreationOptions, onUploaded)
    )
  }

  useEffect(() => cleanUp, [])

  return (
    <UploadWidgetContext.Provider value={uploadWidget}>
      {children}
    </UploadWidgetContext.Provider>
  )
}

export const useUploadWidget = (): CloudinaryUploadWidget => {
  const uploadWidget = useContext(UploadWidgetContext)
  if (!uploadWidget) return null

  return uploadWidget
}

export default UploadWidgetProvider
