import {ReactNode} from 'react'

export type CloudinaryUploadWidget = {
  close(): void
  open(): void
  update?(options: Partial<WidgetOptions>): void
}

interface GoogleTag {
  tag: string
  confidence: number
}

export interface GoogleTaggingData {
  categorization: {
    google_tagging: {status: string; data: GoogleTag[]}
  }
}

export interface Upload {
  name: string
  asset_id: string
  public_id: string
  secure_url: string
  original_filename: string
  format: string
  resource_type: string
  bytes: number
  width: number
  height: number
  pages: number
  info: GoogleTaggingData
}
export interface UploadHandlerProps {
  error
  response: UploadResponse
}

export interface CloudinaryWidgetLib {
  createUploadWidget: (
    options: WidgetOptions,
    handler: (props: UploadHandlerProps) => void
  ) => CloudinaryUploadWidget
}

export enum ResourceType {
  auto = `auto`,
  image = `image`,
  video = `video`,
  raw = `raw`,
}

export type WidgetOptions = {
  cloudName: string
  folder?: string
  uploadPreset?: string
  multiple?: boolean
  showPoweredBy?: boolean
  styles?: object
  language: string
  text: object
  maxFiles?: number
  cropping?: boolean
  croppingAspectRatio?: number
  resourceType?: ResourceType
  maxFileSize?: number
}

export type UploadResponse = {
  info?: Upload
  event: string
}

export interface Props {
  children: ReactNode
  cloudinary?: CloudinaryUploadWidget
  onUploaded(error, response: UploadResponse): void
  widgetOptions?: WidgetOptions
}
