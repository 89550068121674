import {
  Dataset,
  NestedSchema,
  NestedSchemaField,
} from '@myadbox/nebula-service-api'
import {saveAs} from 'file-saver'
import {TFunction} from 'i18next'
import {DataError} from '../types'

const DATASETS_PER_PAGE = 20

export const downloadCsvTemplate = (
  schemaName: string,
  fields: NestedSchemaField[]
): void => {
  const headers = fields.map(d => `"${d.title}"`).join(`,`)
  const csvTemplate = new Blob([headers], {type: `text/csv;charset=utf-8`})
  const filename = `csv-template-${schemaName}.csv`
  saveAs(csvTemplate, filename)
}

export const convertCsvHeaders = (
  headerLine: string,
  schema: NestedSchemaField[]
): string => {
  const headers = headerLine.split(`,`)
  const newHeaders = headers
    .map(h => convertNameToTitle(h, schema, true))
    .join(`,`)
  return newHeaders
}

export const convertNameToTitle = (
  keyword: string,
  schema: NestedSchemaField[],
  reverse = false
): string => {
  const from = reverse ? `title` : `name`
  const to = reverse ? `name` : `title`
  const field = schema.find(f =>
    new RegExp(`^([\\'"]{0,2}?)${f[from]}([\\'"]{0,2}?)$`).test(keyword)
  )
  return field ? keyword.replace(field[from], field[to]) : keyword
}

/*
 * Example error:
 * {
 *    "row1": {
 *      "fieldName1": "Error message",
 *      "fieldName2": "Error message"
 *    },
 *    "row3": { "fieldName1": "Error message" }
 * }
 */

export const validateData = (
  schema: NestedSchemaField[],
  data: Record<string, string | number>[],
  t: TFunction
): DataError => {
  const errors = {}

  data.forEach((row, index) => {
    const rowErrors = {}

    for (const [key, value] of Object.entries(row)) {
      if (key === `id`) continue

      const field = schema.find(f => f.name === key)
      if (!field) {
        rowErrors[key] = t`settings.schemas.validator.invalid`
        continue
      }

      const error = validateField(value, field, t)
      if (error) {
        rowErrors[field.title] = error
      }
    }

    if (Object.keys(rowErrors).length) {
      errors[`row${index}`] = rowErrors
    }
  })

  return Object.keys(errors).length ? errors : null
}

export const validateField = (
  fieldValue: string | number,
  fieldSchema: NestedSchemaField,
  t: TFunction
): string => {
  if (!!fieldSchema?.options?.[`required`] && !fieldValue.toString()) {
    return t`settings.schemas.validator.required`
  } else if (
    fieldSchema.type === `boolean` &&
    !(
      RegExp(/^([\\'"]{0,2}?)(true|false)([\\'"]{0,2}?)$/i).exec(
        fieldValue.toString()
      ) || fieldValue === ``
    )
  ) {
    return t`settings.schemas.validator.boolean`
  } else if (
    fieldSchema.type === `number` &&
    !(
      fieldValue.toString().match(/^([\\'"]{0,2}?)\d*([\\'"]{0,2}?)$/g) ||
      !isNaN(Number(fieldValue))
    )
  ) {
    return t`settings.schemas.validator.number`
  }
}

export const downloadErrors = (errors: DataError, schemaName: string): void => {
  const data = new Blob([JSON.stringify(errors)], {
    type: `text/csv;charset=utf-8`,
  })
  const filename = `csv-upload-errors-${schemaName}.txt`
  saveAs(data, filename)
}

export const downloadSchemaStructure = (schema: NestedSchema): void => {
  const content = {
    name: schema.name,
    title: schema.title,
    fields: getSortedFields(schema.descendants).map(s => ({
      name: s.name,
      title: s.title,
      type: s.type,
      options: s.options,
    })),
  }
  const data = new Blob([JSON.stringify(content)], {
    type: `text/json;charset=utf-8`,
  })
  const filename = `${schema.name}.json`
  saveAs(data, filename)
}

export const getUsageDescription = (
  assetsCount: number,
  usersCount: number,
  t
): string => {
  if (assetsCount === 0 && usersCount === 0)
    return t`settings.schemas.usage.noUsage`

  const assets = assetsCount === 1 ? `single` : `plural`
  const users = usersCount === 1 ? `single` : `plural`

  let desc = ``

  if (assetsCount >= 1) {
    desc += t(`settings.schemas.usage.assets.${assets}`, {
      assetsCount,
    })
  }

  if (assetsCount >= 1 && usersCount >= 1) {
    desc += t`settings.schemas.usage.and`
  }

  if (usersCount >= 1) {
    desc += t(`settings.schemas.usage.users.${users}`, {usersCount})
  }

  return desc
}

export const getColParser = (schemas: NestedSchemaField[]) => {
  const colParser = {}

  schemas.forEach(({name, type}) => {
    /**
     * TODO: add support for other types
     */
    switch (type) {
      case `boolean`:
        colParser[name] = item => item === `true`
        break

      case `number`:
        colParser[name] = `number`
        break

      default:
        colParser[name] = `string`
    }
  })

  return colParser
}

export const getSortedFields = (schemas: NestedSchemaField[]) => {
  if (!schemas) return []

  const headers = Object.values(schemas).sort((a, b) => {
    return Number(a.options?.[`order`] || 0) - Number(b.options?.[`order`] || 0)
  })

  return headers
}

export const getPaginatedDatasets = (datasets = [], page) => {
  let totalPages = 1
  let paginatedDatasets = []
  if (datasets.length) {
    totalPages = Math.ceil(datasets?.length / DATASETS_PER_PAGE)
    page = page > totalPages ? totalPages : page
    paginatedDatasets = datasets.slice(
      page * DATASETS_PER_PAGE - DATASETS_PER_PAGE,
      page * DATASETS_PER_PAGE
    )
  }

  return {
    paginatedDatasets,
    totalPages,
  }
}

export const filterDatasets = (
  datasets: Dataset[],
  filter: string
): Dataset[] => {
  const filterRegex = new RegExp(filter, `i`)

  return datasets.filter(({data}) => {
    return Object.values(data).some((value: string) => {
      return filterRegex.test(value)
    })
  })
}

export const prepareDataRecordsForUpsert = (
  schema: NestedSchemaField[],
  data: Record<string, string | number>[]
): Record<string, string | number | null>[] => {
  return data.map(row => prepareRecordForUpsert(schema, row))
}

export const prepareRecordForUpsert = (
  schema: NestedSchemaField[],
  data: Record<string, string | number>
): Record<string, string | number | null> => {
  const cleanedRow = {}
  for (const [key, value] of Object.entries(data)) {
    const field = schema.find(f => f.name === key)
    if (field?.type === `number` && value === ``) {
      cleanedRow[key] = null
    } else if (field?.type === `boolean` && value === ``) {
      cleanedRow[key] = false
    } else {
      cleanedRow[key] = value
    }
  }
  return cleanedRow
}
