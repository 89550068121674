import {useLazyQuery, useMutation, useQuery} from '@apollo/client'
import {useLocation} from '@reach/router'
import {useCallback, useState} from 'react'
import {
  TOGGLE_WHITE_LABEL,
  UPDATE_ACCOUNT_BRAND_ASSET_CONFIG,
  UPDATE_ACCOUNT_IMAGE_CONFIG,
  UPDATE_ACCOUNT_INTEGRATION,
  UPDATE_AI_TAGS_CONFIG,
  UPDATE_BILLING,
} from '../../mutations'
import {
  FETCH_ACCOUNT,
  FETCH_ACCOUNTS,
  FETCH_ACCOUNT_BILLING,
  FETCH_ACCOUNT_INTEGRATIONS,
} from '../../queries'
import type {Account, UseAccountOutput} from '../../types'
import {updateConfiguration} from '../../updaters'

export const useAccount = (): UseAccountOutput => {
  const {hostname} = useLocation()
  const [accountName, setAccountName] = useState<string>(
    hostname?.split(`.`)[0]
  )

  const {data} = useQuery<{account: Account}>(FETCH_ACCOUNT, {
    variables: {accountName},
    skip: !accountName,
  })

  if (data?.account && data.account?.accountName !== accountName) {
    setAccountName(data.account?.accountName)
  }

  const isEnterprise =
    data?.account?.configuration.subscriptionType === `ENTERPRISE`

  const [lazyFetchAccountIntegrations, fetchAccountIntegrationsResults] =
    useLazyQuery(FETCH_ACCOUNT_INTEGRATIONS)
  const fetchAccountIntegrations = useCallback(
    (accountName: string) => {
      return lazyFetchAccountIntegrations({variables: {accountName}})
    },
    [lazyFetchAccountIntegrations]
  )

  const [fetchAccounts, fetchAccountsResults] = useLazyQuery(FETCH_ACCOUNTS)

  const [lazyFetchAccountBilling, fetchAccountBillingResult] = useLazyQuery(
    FETCH_ACCOUNT_BILLING
  )
  const fetchAccountBilling = useCallback(() => {
    lazyFetchAccountBilling()
  }, [lazyFetchAccountBilling])

  const [doUpdateAccountImageConfig, updateAccountImageConfigResponse] =
    useMutation(UPDATE_ACCOUNT_IMAGE_CONFIG)

  const updateAccountImageConfig = useCallback(
    inputParam => {
      return doUpdateAccountImageConfig({
        variables: {
          imageConfigInput: inputParam,
        },
      })
    },
    [doUpdateAccountImageConfig]
  )

  const [
    doUpdateAccountBrandAssetConfig,
    updateAccountBrandAssetConfigResponse,
  ] = useMutation(UPDATE_ACCOUNT_BRAND_ASSET_CONFIG)

  const updateAccountBrandAssetConfig = useCallback(
    inputParam => {
      return doUpdateAccountBrandAssetConfig({
        variables: {
          brandAssetConfigInput: inputParam,
        },
      })
    },
    [doUpdateAccountBrandAssetConfig]
  )
  const [doUpdateBilling, updateBillingResponse] = useMutation(UPDATE_BILLING)
  const updateBilling = useCallback(
    billingInput => {
      return doUpdateBilling({
        variables: {billingInput: {...billingInput, __typename: undefined}},
      })
    },
    [doUpdateBilling]
  )

  const [doUpdateAITagsConfig, updateAITagsConfigResponse] = useMutation(
    UPDATE_AI_TAGS_CONFIG
  )
  const updateAITagsConfig = useCallback(
    (aiTagsEnabled: boolean) => {
      return doUpdateAITagsConfig({
        variables: {aiTagsEnabled},
        update: updateConfiguration(
          accountName,
          `aiTagsEnabled`,
          `updateAITagsConfig`
        ),
      })
    },
    [accountName, doUpdateAITagsConfig]
  )

  const [doToggleWhiteLabel, toggleWhiteLabelResponse] =
    useMutation(TOGGLE_WHITE_LABEL)
  const toggleWhiteLabel = useCallback(() => {
    return doToggleWhiteLabel({
      update: updateConfiguration(
        accountName,
        `isWhiteLabelled`,
        `toggleWhiteLabel`
      ),
    })
  }, [accountName, doToggleWhiteLabel])

  const [doUpdateAccountIntegration, updateAccountIntegrationResponse] =
    useMutation(UPDATE_ACCOUNT_INTEGRATION)
  const updateAccountIntegration = useCallback(
    accountIntegrationInput => {
      return doUpdateAccountIntegration({
        variables: {accountIntegrationInput},
        refetchQueries: [
          {
            query: FETCH_ACCOUNT_INTEGRATIONS,
            variables: {accountName},
          },
        ],
      })
    },
    [doUpdateAccountIntegration, accountName]
  )

  return {
    account: data?.account,
    accountName,
    fetchAccounts,
    fetchAccountsResults,
    fetchAccountBilling,
    fetchAccountBillingResult,
    fetchAccountIntegrations,
    fetchAccountIntegrationsResults,
    isEnterprise,
    storageLimit: data?.account?.billing?.limits?.storage,
    toggleWhiteLabel,
    toggleWhiteLabelResponse,
    updateBilling,
    updateBillingResponse,
    updateAITagsConfig,
    updateAITagsConfigResponse,
    updateAccountImageConfig,
    updateAccountImageConfigResponse,
    updateAccountBrandAssetConfig,
    updateAccountBrandAssetConfigResponse,
    updateAccountIntegration,
    updateAccountIntegrationResponse,
  }
}
