import {gql} from '@apollo/client'

export const FETCH_INTEGRATIONS = gql`
  query Integrations @api(name: "integrations") {
    integrations {
      id
      name
      description
      intro
      logo
      config
      category
      isEnabledByDefault
      isActive
    }
  }
`
