import {Banner} from '@myadbox/gatsby-theme-nebula'
import {useAccount, useIntegrations} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {useEffect} from 'react'

import {SettingsTabComponentName, SettingsViewProps} from '../helpers'
import IntegrationList from './IntegrationList'
import IntegrationView from './IntegrationView'

export const Integrations = ({viewType, id}: SettingsViewProps) => {
  const {t} = useTranslation()
  const {
    fetchIntegrations,
    fetchIntegrationsResults: {data, error, loading},
  } = useIntegrations()

  const {
    account,
    fetchAccountIntegrations,
    fetchAccountIntegrationsResults: {
      data: accountIntegrationsData,
      error: fetchAccountIntegrationsError,
    },
  } = useAccount()

  const accountIntegrations =
    accountIntegrationsData?.accountIntegrations?.integrations

  useEffect(() => {
    fetchIntegrations()
    if (account) fetchAccountIntegrations(account.accountName)
  }, [account, fetchIntegrations, fetchAccountIntegrations])

  const {integrations} = data || {}

  if (!data || loading) return <div>{t`settings.integrations.loading`}</div>

  if (error || fetchAccountIntegrationsError)
    return (
      <div>
        {error && (
          <div className="mb-4">
            <Banner fadeIn intent="error">
              {t`settings.integrations.error`}
            </Banner>
          </div>
        )}
      </div>
    )

  const integration =
    id && integrations?.find(integration => integration.id === id)

  const accountIntegration = accountIntegrations?.find(
    i => i?.integrationId === integration?.id
  )

  const activeIntegrationIds =
    accountIntegrations?.filter(i => i?.enabled).map(i => i.integrationId) ||
    null

  return viewType === `view` ? (
    <IntegrationView
      integration={integration}
      accountIntegration={accountIntegration}
    />
  ) : (
    <IntegrationList
      integrations={integrations}
      activeIntegrationIds={activeIntegrationIds}
      error={error}
    />
  )
}

Integrations.displayName = `Integrations` satisfies SettingsTabComponentName
export default Integrations
