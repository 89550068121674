import {FC} from 'react'
import {I18nextProvider} from 'react-i18next'
import useProfile from '../../../hooks/useProfile'
import i18n from '../../../locales/i18n'
import HeaderLayout, {HeaderProps} from '../HeaderLayout/HeaderLayout'
import MenuOpenButton from './MenuOpenButton/MenuOpenButton'
import Support from './Support/Support'

const IndexHeader: FC<HeaderProps> = ({
  children,
  endContent,
  tours,
  ...props
}) => {
  const profile = useProfile()
  return (
    <HeaderLayout
      startContent={<MenuOpenButton />}
      endContent={
        <>
          {endContent && (
            <div
              className={`
                flex
                items-center
                border-r
                pr-3
                md:hidden
              `}
            >
              {endContent}
            </div>
          )}
          <I18nextProvider i18n={i18n}>
            <Support user={profile} tours={tours} />
          </I18nextProvider>
        </>
      }
      {...props}
    >
      {children}
    </HeaderLayout>
  )
}

export default IndexHeader
