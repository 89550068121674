import {Banner} from '@myadbox/gatsby-theme-nebula'
import {useAccount} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {
  ButtonHTMLAttributes,
  ReactElement,
  ReactNode,
  createElement,
  useMemo,
  useState,
} from 'react'
import {createPortal} from 'react-dom'
import {
  CloudinaryUploadWidget,
  ResourceType,
} from '../../types/shared/upload.types'
import UploadWidgetProvider, {UploadWidgetContext} from '../UploadWidget'
import {
  DefaultUploadTrigger,
  RawUploadTrigger,
  TriggerProps,
} from './UploadButtonTriggers'
import {
  ResponseHandler,
  abort,
  handleCloudinaryResponse,
  widgetSizeConfig,
} from './helpers'
interface Props extends ButtonHTMLAttributes<HTMLElement> {
  cropping?: boolean
  croppingAspectRatio?: number
  multiple?: boolean
  maxFileSize?: number
  resourceType?: ResourceType
  trigger?: (props: TriggerProps) => ReactElement
  triggerProps?: TriggerProps
  successHandler: ResponseHandler
  closeHandler?: ResponseHandler
  showWarning?: boolean
  sources?: string[]
  clientAllowedFormats?: string[]
  tags?: string[]
  minImageWidth?: number
  minImageHeight?: number
}

const noop = () => null

const UploadButton = ({
  className = ``,
  cropping = false,
  croppingAspectRatio,
  multiple = true,
  maxFileSize,
  resourceType = ResourceType.auto,
  trigger,
  triggerProps,
  successHandler,
  sources,
  showWarning = false,
  closeHandler = noop,
  clientAllowedFormats,
  tags,
  minImageHeight,
  minImageWidth,
}: Props) => {
  const {t} = useTranslation()
  const [showBanner, setShowBanner] = useState(false)
  const {isEnterprise, account} = useAccount()
  const accountName = account?.accountName
  const uploadTrigger =
    trigger || (isEnterprise ? RawUploadTrigger : DefaultUploadTrigger)

  const close = () => {
    setShowBanner(false)
    closeHandler()
  }

  const widgetOptions = useMemo(
    () => ({
      multiple,
      cropping,
      croppingAspectRatio,
      resourceType,
      folder: accountName,
      sources,
      clientAllowedFormats,
      tags,
      minImageHeight,
      minImageWidth,
    }),
    [
      multiple,
      cropping,
      croppingAspectRatio,
      resourceType,
      accountName,
      sources,
      clientAllowedFormats,
      tags,
      minImageHeight,
      minImageWidth,
    ]
  )

  return (
    <div className={className}>
      {accountName && (
        <UploadWidgetProvider
          onUploaded={handleCloudinaryResponse({
            success: successHandler,
            close,
            abort,
          })}
          widgetOptions={widgetOptions}
        >
          <UploadWidgetContext.Consumer>
            {(widget: CloudinaryUploadWidget): ReactNode =>
              createElement(uploadTrigger, {
                ...(triggerProps || {}),
                onClickHandler: () => {
                  widget?.update(
                    maxFileSize ? {maxFileSize} : {...widgetSizeConfig.default}
                  )
                  widget?.open()
                },
                onSecondaryClickHandler: () => {
                  widget?.update({...widgetSizeConfig.large})
                  widget?.open()
                  if (showWarning) {
                    setShowBanner(true)
                  }
                },
                t,
              })
            }
          </UploadWidgetContext.Consumer>
        </UploadWidgetProvider>
      )}
      {isEnterprise &&
        showWarning &&
        createPortal(
          <div
            className={`
              absolute
              w-full
            `}
            style={{zIndex: 1001000}}
          >
            {showBanner && (
              <div
                className={`
                  mx-auto
                  my-3
                  max-w-lg
                  md:max-w-xl
                `}
              >
                <Banner
                  fadeIn
                  intent="warning"
                  onDismiss={() => setShowBanner(false)}
                >
                  {t(`assets.uploadButton.rawWarning`)}
                </Banner>
              </div>
            )}
          </div>,
          document.body
        )}
    </div>
  )
}

export default UploadButton
