import {Image} from '@myadbox/gatsby-theme-nebula'
import {FacebookIcon, InstagramIcon} from '@myadbox/stellar-ui'

// Logo Renderer
interface IntegrationLogo {
  name: string
  logo?: string
}

interface Icon {
  name: string
  icon: JSX.Element
}

export const renderLogo = ({
  name: integrationName,
  logo: logoUrl,
}: IntegrationLogo): JSX.Element => {
  const icons: Icon[] = [
    {
      name: `facebook`,
      icon: <FacebookIcon size={40} />,
    },
    {
      name: `instagram`,
      icon: <InstagramIcon size={40} />,
    },
  ]

  const icon = icons.find(i => integrationName?.toLowerCase().includes(i.name))
  if (icon) {
    return icon.icon
  }

  return <Image src={logoUrl} />
}

export const badgeNameTranslation = {
  SOCIAL: `settings.integrations.badge.social`,
  PRINTER: `settings.integrations.badge.printer`,
  SESIMI_SUITE: `settings.integrations.badge.sesimiSuite`,
}
