import {Button, Modal} from '@myadbox/gatsby-theme-nebula'
import {NestedSchema} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {HTMLAttributes, ReactNode} from 'react'
import CsvUploader from '../CsvUploader'

interface Props extends HTMLAttributes<HTMLElement> {
  nestedSchema: NestedSchema
  disabled?: boolean
  refetchDatasets: () => void
}

const CsvUploaderModal = ({nestedSchema, disabled, refetchDatasets}: Props) => {
  const {t} = useTranslation()

  return (
    <Modal
      accessibleTitle={t`settings.schemas.csvUploader.title`}
      trigger={({open}): ReactNode => (
        <Button
          variant="primary"
          onClick={open}
          disabled={disabled}
          className={`
            whitespace-nowrap
          `}
        >
          {t`settings.schemas.csvUploader.btnLabel`}
        </Button>
      )}
    >
      {({close}): ReactNode => (
        <CsvUploader
          close={close}
          nestedSchema={nestedSchema}
          refetchDatasets={refetchDatasets}
        />
      )}
    </Modal>
  )
}

export default CsvUploaderModal
