import {Amplify} from '@aws-amplify/core'
import {
  useFeatureFlags,
  usePluginOptions,
} from '@myadbox/gatsby-theme-nebula/hooks'
import {Account, blankAccount, useAccount} from '@myadbox/nebula-service-api'
import {useLocation} from '@reach/router'
import {
  ReactElement,
  createContext,
  useContext,
  useDebugValue,
  useEffect,
  useState,
} from 'react'
import {ADMIN_URL} from '../../utils/helpers'

interface Props {
  children: ReactElement
}

export const CognitoContext = createContext(null)
CognitoContext.displayName = `CognitoContext`

const LANGUAGE_REGEX = `/[a-z]{2}-[A-Z]{2}`
export const CognitoProvider = ({children}: Props) => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [isConfigured, setIsConfigured] = useState<boolean>(false)

  const {hasFeaturesInit} = useFeatureFlags()
  const pluginOptionsConfig = usePluginOptions()
  const {protocol, hostname, pathname} = useLocation()
  const {account} = useAccount()

  useEffect(() => {
    if (hasFeaturesInit) {
      let nextIsAdmin = false
      if (new RegExp(`^((${LANGUAGE_REGEX}(/)?)|/)$`).test(pathname)) {
        localStorage.removeItem(`isAdmin`)
      } else if (
        new RegExp(`^(${LANGUAGE_REGEX})?${ADMIN_URL}(/)?$`).test(pathname)
      ) {
        localStorage.setItem(`isAdmin`, `true`)
        nextIsAdmin = true
      } else {
        const isAdminLocal = localStorage.getItem(`isAdmin`)
        if (isAdminLocal === `true`) {
          nextIsAdmin = true
        }
      }

      const configs = account?.configuration?.loginConfig

      if (configs) {
        const {userPoolId, userPoolAppId, ssoEnabled, ssoConfig} = configs
        const {region, adminPoolId, adminPoolAppId} = pluginOptionsConfig
        const url = `${protocol}//${hostname}/`

        Amplify.configure({
          Auth: {
            region,
            userPoolId: nextIsAdmin ? adminPoolId : userPoolId,
            userPoolWebClientId: nextIsAdmin ? adminPoolAppId : userPoolAppId,
            oauth: ssoEnabled
              ? {
                  domain: ssoConfig.domain,
                  scope: ssoConfig?.scope?.split(`,`),
                  redirectSignIn: url,
                  redirectSignOut: `${url}logout/`,
                  responseType: `token`,
                }
              : {},
          },
        })

        if (!isConfigured) setIsConfigured(true)
      }

      if (isAdmin !== nextIsAdmin) {
        setIsAdmin(nextIsAdmin)
      }
    }
  }, [
    hasFeaturesInit,
    pathname,
    account,
    isConfigured,
    isAdmin,
    pluginOptionsConfig,
    protocol,
    hostname,
  ])

  return (
    <CognitoContext.Provider value={{isAdmin, isConfigured, account}}>
      {children}
    </CognitoContext.Provider>
  )
}
export const useCognito = () => {
  const {isAdmin, isConfigured, account} = useContext<{
    isAdmin: boolean
    isConfigured: boolean
    account: Account
  }>(CognitoContext) || {
    isAdmin: false,
    isConfigured: false,
    account: blankAccount,
  }
  useDebugValue(`isAdmin: ${isAdmin} isConfigured: ${isConfigured}`)
  return {isAdmin, isConfigured, account}
}

export default CognitoProvider
