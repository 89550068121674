import {useRef, useEffect} from 'react'

const blockerFn = e => {
  // console.log(`change blocked`, e)
  e.preventDefault()
  e.stopPropagation()
}

/**
 * Prevents any change event from bubbling to the document. This is to prevent triggering the darkMode localStorage change which is a bug in use-persisted-state, introduced by React 18.
 * @returns a ref you can place on a UI-wrapping div that will prevent change events from bubbling up any further.
 */
export const useChangeBlocker = () => {
  const changeBlockerRef = useRef<HTMLElement>(null)

  useEffect(() => {
    changeBlockerRef.current = document.documentElement
    // this local variable ensures the DOM reference is still accessible by the time the cleanup function runs, ie. after the component that uses this hook has unmounted
    const changeBlockerVar = changeBlockerRef.current
    changeBlockerVar.addEventListener(`change`, blockerFn)
    return () => {
      changeBlockerVar?.removeEventListener(`change`, blockerFn)
    }
  }, [])

  return changeBlockerRef
}
