import {HTMLAttributes} from 'react'

interface Props extends Omit<HTMLAttributes<HTMLElement>, `color`> {
  tag?: `p` | `span` | `em` | `h1` | `h2` | `h3` | `h4` | `h5` | `h6` | `legend`
  display?: keyof typeof displayClassNames
  variant?: TextVariant
  color?: Color
}

export type TextVariant = (typeof variants)[number]
type Color = keyof typeof colors

const variants = [
  `body`,
  `tiny`,
  `bodySmall`,
  `heading`,
  `subHeading`,
  `caption`,
  `headingLarge`,
] as const

const colors = {
  textPrimary: `text-ui-900`,
  textSecondary: `text-ui-600`,
  inherit: `text-inherit`,
}

const displayClassNames = {
  block: `block`,
  'inline-block': `inline-block`,
  flex: `flex`,
  'inline-flex': `inline-flex`,
  grid: `grid`,
  'inline-grid': `inline-grid`,
}

export const Text = ({
  children,
  tag: Element = `p`,
  display = `block`,
  variant = `body`,
  color,
  className = ``,
  ...props
}: Props) => {
  const displayClass = display
    ? displayClassNames[display]
    : displayClassNames.block

  return (
    <Element
      className={`
        ${className}
        ${color ? colors[color] : ``}
        ${getVariant(variant, color)}
        ${displayClass}
      `}
      {...props}
    >
      {children}
    </Element>
  )
}

export default Text

const getVariant = (variant: TextVariant, color: Color | undefined) => {
  const variants = {
    body: `
      text-sm
      leading-5
      ${colors[color ?? `textPrimary`]}
    `,
    tiny: `
      text-tiny
    `,
    bodySmall: `
      text-xs
      leading-5
      ${colors[color ?? `textPrimary`]}
    `,
    heading: `
      text-sm
      font-medium
      leading-5
      ${colors[color ?? `textPrimary`]}
    `,
    headingLarge: `
      text-xl
      font-light
      leading-tight
      ${colors[color ?? `textSecondary`]}
    `,
    subHeading: `
      text-sm
      font-light
      leading-5
      ${colors[color ?? `textSecondary`]}
    `,
    caption: `
      text-xs
      text-ui-900
      leading-5
      font-medium
      tracking-wide
      uppercase
      ${colors[color ?? `textSecondary`]}
    `,
  }
  return variants[variant]
}
