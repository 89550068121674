import {
  AcademicCapIcon,
  BanknotesIcon,
  CalendarDaysIcon,
  ChartPieIcon,
  ClipboardDocumentCheckIcon,
  FolderIcon,
  HomeIcon,
  SwatchIcon,
  ViewColumnsIcon,
} from '@heroicons/react/24/outline'
import {
  AcademicCapIcon as AcademicCapSolidIcon,
  BanknotesIcon as BanknotesSolidIcon,
  CalendarDaysIcon as CalendarDaysSolidIcon,
  ChartPieIcon as ChartPieSolidIcon,
  ClipboardDocumentCheckIcon as ClipboardDocumentCheckSolidIcon,
  FolderIcon as FolderSolidIcon,
  HomeIcon as HomeSolidIcon,
  SwatchIcon as SwatchSolidIcon,
  ViewColumnsIcon as ViewColumnsSolidIcon,
} from '@heroicons/react/24/solid'
import {getJwt, useAccount} from '@myadbox/nebula-service-api'
import {useLocation} from '@reach/router'
import type {HTMLAttributes} from 'react'
import {memo} from 'react'
import {useTranslation} from 'react-i18next'

import useCognitoAuthToken from '../../hooks/useCognitoAuthToken'
import MenuItem from './MenuItem'
import {getCoreAppUrl, getTitanAppUrl} from './helpers'
import useFeatureMenuItems from './useFeatureMenuItems'
interface Props extends HTMLAttributes<HTMLElement> {
  mobile?: boolean
}

export const TheMenu = ({className = ``, mobile}: Props) => {
  const {
    t,
    i18n: {language},
  } = useTranslation(`layout`)
  const {hostname, protocol} = useLocation()

  const token = useCognitoAuthToken()
  const jwt = getJwt()
  const {account} = useAccount()
  const {shownFeatures} = useFeatureMenuItems()
  const coreUrl = account?.configuration?.coreUrl
  const brandAssetId = account?.configuration?.brandAssetId

  const brandAssetUrl = brandAssetId
    ? `/assets/brand/${brandAssetId}/`
    : `/assets/`

  return (
    <nav
      className={`
        ${className}
        flex-1
      `}
    >
      <MenuItem
        mobile={mobile}
        to="/dashboard/"
        defaultIcon={<HomeIcon width={24} />}
        activeIcon={<HomeSolidIcon width={24} />}
        label={t`home`}
      />
      <MenuItem
        mobile={mobile}
        to="/assets/"
        defaultIcon={<FolderIcon width={24} />}
        activeIcon={<FolderSolidIcon width={24} />}
        className={`mt-px`}
        label={t`assets`}
      />
      {shownFeatures.includes(`brand`) && (
        <MenuItem
          mobile={mobile}
          to={brandAssetUrl}
          defaultIcon={<SwatchIcon width={24} />}
          activeIcon={<SwatchSolidIcon width={24} />}
          className={`mt-px`}
          label={t`brand`}
        />
      )}
      {shownFeatures.includes(`reports`) && (
        <MenuItem
          mobile={mobile}
          to="/reports/"
          defaultIcon={<ChartPieIcon width={24} />}
          activeIcon={<ChartPieSolidIcon width={24} />}
          className={`mt-px`}
          label={t`reports`}
        />
      )}
      {shownFeatures.includes(`funds`) && (
        <MenuItem.External
          mobile={mobile}
          href={getCoreAppUrl(coreUrl, `myfunds`, token, language)}
          defaultIcon={<BanknotesIcon width={24} />}
          activeIcon={<BanknotesSolidIcon width={24} />}
          className={`mt-px`}
          label={t`funds`}
          target="_self"
        />
      )}
      {shownFeatures.includes(`planner`) && (
        <MenuItem.External
          mobile={mobile}
          href={getCoreAppUrl(coreUrl, `myplanner`, token, language)}
          defaultIcon={<CalendarDaysIcon width={24} />}
          activeIcon={<CalendarDaysSolidIcon width={24} />}
          className={`mt-px`}
          label={t`planner`}
          target="_self"
        />
      )}
      {shownFeatures.includes(`awards`) && (
        <MenuItem.External
          mobile={mobile}
          href={getCoreAppUrl(coreUrl, `myawards`, token, language)}
          defaultIcon={<AcademicCapIcon width={24} />}
          activeIcon={<AcademicCapSolidIcon width={24} />}
          className={`mt-px`}
          label={t`awards`}
          target="_self"
        />
      )}
      {shownFeatures.includes(`workflows`) && (
        <MenuItem.External
          mobile={mobile}
          href={getCoreAppUrl(coreUrl, `workflows`, token, language)}
          defaultIcon={<ViewColumnsIcon width={24} />}
          activeIcon={<ViewColumnsSolidIcon width={24} />}
          className={`mt-px`}
          label={t`workflows`}
          target="_self"
        />
      )}
      {shownFeatures.includes(`approvals`) && (
        <MenuItem.External
          mobile={mobile}
          href={getTitanAppUrl(protocol, hostname, jwt)}
          defaultIcon={<ClipboardDocumentCheckIcon width={24} />}
          activeIcon={<ClipboardDocumentCheckSolidIcon width={24} />}
          className={`mt-px`}
          label={t`approvals`}
          target="_self"
        />
      )}
      {shownFeatures.includes(`brandTitan`) && (
        <MenuItem.External
          mobile={mobile}
          href={getTitanAppUrl(protocol, hostname, jwt, `/brand`)}
          defaultIcon={<SwatchIcon width={24} />}
          activeIcon={<SwatchSolidIcon width={24} />}
          className={`mt-px`}
          label={t`brand`}
          target="_self"
        />
      )}
    </nav>
  )
}

export default TheMenu

export const Memo = memo(TheMenu)
