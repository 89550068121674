import {gql} from '@apollo/client'

export const BRAND_SETTINGS = gql`
  fragment BrandSettings on Brand {
    typography
    colors {
      basePalette
      extendedPalette
    }
    css
    assets {
      logo
    }
  }
`
