import {gql} from '@apollo/client'

export const INTEGRATE = gql`
  mutation Integrate($input: IntegrationLogInput!) @api(name: "integrations") {
    integrate(input: $input) {
      id
      accountId
      integrationId
      action
      data
      assetId
    }
  }
`
