import {FC} from 'react'
import {
  PropsExternal,
  iconClasses as mobileIconClasses,
  commonClassName,
  inActiveClassName,
} from '../MenuItem/MenuItem'
import {anchorClassName} from '../../IconTab/IconTab'
import {slugifyText} from '../../../utils/helpers'
import ConditionalIcon from '../../ConditionalIcon'

const iconClasses = `md:w-6 md:h-6 shrink-0 w-5
 h-5 text-ui-400 dark:text-ui-300`
const labelClasses = `text-ui-600 dark:text-ui-400 block text-tiny font-normal text-center titlecase`

const MenuItemExternal: FC<PropsExternal> = ({
  href,
  className = ``,
  label,
  mobile,
  defaultIcon,
  activeIcon,
  target = `_self`,
}) => {
  const slug = slugifyText(label)

  const conditionalIconClasses = mobile ? mobileIconClasses : iconClasses
  const anchorClasses = mobile
    ? `${commonClassName} ${inActiveClassName}`
    : anchorClassName

  const relProps = target === `_blank` ? {rel: `noreferrer`} : {}

  return (
    <a
      href={href}
      target={target}
      className={`${className} ${anchorClasses}`}
      {...relProps}
    >
      <ConditionalIcon
        className={conditionalIconClasses}
        defaultIcon={defaultIcon}
        activeIcon={activeIcon}
        current={false}
      />
      <span id={`tab-label-${slug}`} className={mobile ? `` : labelClasses}>
        {label}
      </span>
    </a>
  )
}

export default MenuItemExternal
