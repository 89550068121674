import {FC, useContext, HTMLAttributes} from 'react'

import {MobileMenuContext} from '../../../../contexts/MenuContext'
import {XMarkIcon} from '@heroicons/react/24/outline'

interface Props extends HTMLAttributes<HTMLButtonElement> {
  expanded: boolean
}

const MenuCloseButton: FC<Props> = ({expanded = false, ...props}) => {
  const {closeMenu} = useContext(MobileMenuContext)
  return (
    <button
      {...props}
      hidden={!expanded}
      aria-hidden={!expanded}
      className={`
        hocus:bg-white/10
        flex
        h-10
        w-10
        items-center
        justify-center
        ${expanded ? `block` : `hidden`}
      `}
      aria-label="Close sidebar"
      onClick={closeMenu}
    >
      <XMarkIcon className={`h-6 w-6 text-white`} />
    </button>
  )
}

export default MenuCloseButton
