import type {
  DatasetUsage,
  NestedSchemaField,
  NestedSchemaOption,
  NestedSchemaType,
} from '@myadbox/nebula-service-api'
import type {ReactElement} from 'react'
import * as Yup from 'yup'
import {prepareRecordForUpsert} from '../helpers'

const fieldLabels = {
  string: `Text`,
  paragraph: `Paragraph`,
  number: `Number`,
  boolean: `Checkbox`,
  uri: `Hyperlink`,
} as const

const fieldHtmlTypes = {
  string: `text`,
  paragraph: `textarea`,
  number: `number`,
  boolean: `checkbox`,
  uri: `url`,
} as const

const isParagraph = (field: Partial<NestedSchemaField>) =>
  field.type === `string` && field.options?.paragraph

export const getReadableType = (
  field: NestedSchemaField,
  requiredText = `(required)`
) => {
  const fieldType = isParagraph(field) ? `paragraph` : field.type
  const requiredIndicator = field.options?.required ? (
    <span className="dark:text-ui-400 text-ui-600 text-tiny ml-1 lowercase">
      {requiredText}
    </span>
  ) : (
    ``
  )

  return (
    <span>
      {fieldLabels[fieldType]}
      {requiredIndicator}
    </span>
  )
}

export const getHtmlFieldType = (
  type: NestedSchemaType = `string`,
  options: NestedSchemaOption = {}
): string => {
  const fieldType = isParagraph({type, options}) ? `paragraph` : type
  return fieldHtmlTypes[fieldType]
}

export const handleSubmit = ({
  values,
  recordId,
  addDataset,
  updateDataset,
  schemaId,
  nestedSchemaFields,
}) => {
  const value = values.rows.find(({id}) => id === recordId)
  if (!value) return
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {__typename, ...rest} = value
  if (rest.id === `new`) {
    addDataset(schemaId, prepareRecordForUpsert(nestedSchemaFields, rest.data))
  } else
    updateDataset(
      rest.id,
      prepareRecordForUpsert(nestedSchemaFields, rest.data)
    )
}

export const yupValidators = type => {
  switch (type) {
    case `string`:
      return Yup.string()
    case `number`:
      // Transform empty values to undefined as they are allowed for undefined fields
      return Yup.number().transform((value, originalValue) =>
        value === `` || originalValue === `` || originalValue === null
          ? undefined
          : value
      )
    case `boolean`:
      return Yup.boolean()
    case `uri`:
      return Yup.string().url()
    default:
      return Yup.string()
  }
}

export const getObjectShape = (fields: NestedSchemaField[]) => {
  const objectShape = {}
  fields.forEach(field => {
    objectShape[field.name] = yupValidators(field.type).when([], {
      is: () => field.options.required,
      then: yupValidators(field.type).required(`Required`),
      otherwise: yupValidators(field.type).notRequired(),
    })
  })
  return objectShape
}

export const getEditableTableSchema = (fields: NestedSchemaField[]) => {
  return Yup.object().shape({
    rows: Yup.array()
      .of(
        Yup.object().shape({
          data: Yup.object().shape(getObjectShape(fields)),
        })
      )
      .required(`Must have rows`),
  })
}

export const ActionsWrapper = ({
  children,
}: {
  children: ReactElement | ReactElement[]
}) => (
  <div
    className={`
      after:content
      after:backdrop-blur-3
      dark:after:bg-ui-0/60
      after:bg-ui-200/60
      -my-2
      py-2
      pl-2
      after:absolute

      after:inset-0
      after:z-[-1]
      after:mb-0.5
    `}
    style={{
      marginRight: `-1px`,
    }}
  >
    <div
      className={`
        -ml-3
        -mt-2
        flex
        justify-end
        gap-1
      `}
      style={{
        translate: `0 0.25rem`,
      }}
    >
      {children}
    </div>
  </div>
)

export const getUsageMap = (usage: DatasetUsage[]) => {
  if (!usage) return {}

  return usage.reduce((acc, {datasetId, assetsCount, usersCount}) => {
    acc[datasetId] = {assetsCount, usersCount}
    return acc
  }, {})
}
