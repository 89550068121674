import {gql} from '@apollo/client'

export const FETCH_ACCOUNT = gql`
  query Account($accountName: String!) @api(name: "accounts") {
    account(accountName: $accountName) {
      id
      name
      accountName
      configuration {
        dashboardUrl
        loginBackgroundUrl
        logoUrl
        coreUrl
        subscriptionType
        aiTagsEnabled
        isWhiteLabelled
        brandAssetId
        brandName
        loginConfig {
          userPoolAppId
          userPoolId
          ssoEnabled
          ssoConfig {
            idps {
              name
              identifier
            }
            domain
            scope
            attributeMaps {
              attribute
              binding
              data {
                key
                value
              }
            }
          }
        }
      }
      products {
        awards
        funds
        planner
        workflows
        brand
        approvals
        brandTitan
      }
      billing {
        limits {
          storage
          users
          variables
        }
      }
      deactivated
    }
  }
`

export const FETCH_ACCOUNT_INTEGRATIONS = gql`
  query AccountIntegrations($accountName: String!) @api(name: "gateway") {
    accountIntegrations(accountName: $accountName) {
      id
      integrations {
        integrationId
        enabled
        values
        integration {
          name
          logo
          description
          intro
          config
        }
      }
    }
  }
`

export const FETCH_ACCOUNTS = gql`
  query Accounts @api(name: "accounts") {
    accounts {
      id
      name
      accountName
      configuration {
        subscriptionType
      }
    }
  }
`

export const FETCH_ACCOUNT_BILLING = gql`
  query AccountBilling @api(name: "accounts") {
    accountBilling {
      id
      billing {
        price
        billingEmail
        currency
        paymentTerm
        additionalInfo
        stripeCustomerId
        autoBilling
      }
    }
  }
`
