import {ResourceType, UploadResponse} from '../../types/shared/upload.types'

export enum UploadType {
  Asset = `Asset`,
  Csv = `CSV`,
}

export const widgetSizeConfig = {
  default: {
    maxImageFileSize: 314572800,
    maxVideoFileSize: 3146063544,
    maxFileSize: 5368709120,
    resourceType: ResourceType.auto,
  },
  large: {
    maxImageFileSize: 5368709120,
    maxVideoFileSize: 5368709120,
    maxFileSize: 5368709120,
    resourceType: ResourceType.raw,
  },
}

export type ResponseHandler = (data?: UploadResponse) => void
export type CloudinaryResponders = Record<string, ResponseHandler>

export const handleCloudinaryResponse = (callbacks: CloudinaryResponders) => {
  return (error, response: UploadResponse = {event: ``}): void => {
    if (response.event in callbacks !== true) {
      return
    } else {
      callbacks[response.event](response)
    }
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
}

export const abort = (): void => {
  // console.log(`user closed or abort the widget.`)
}
