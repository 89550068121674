import type {FC, HTMLAttributes, ReactElement} from 'react'
import {cloneElement, useMemo, useRef, useState} from 'react'
import {I18nextProvider} from 'react-i18next'
import {MobileMenuContext} from '../../../contexts/MenuContext'
import i18n from '../../../locales/i18n'
import * as styles from '../../../styles/common'
import IndexHeader from '../../Headers/IndexHeader'
import {SidebarOffscreen, SidebarStatic} from '../../Sidebars'
import Wrap from '../../TheWrap'
import {useChangeBlocker} from '../hooks/useChangeBlocker'

type PureProps = LayoutProps
export interface LayoutProps extends HTMLAttributes<HTMLElement> {
  hideHead?: boolean
  hideSide?: boolean
  Header?: ReactElement
  contentPad?: boolean
  mainInnerClassName?: string
}

export const wrapClassName = `
  text-ui-600
  bg-ui-200
  dark:bg-ui-0
  dark:text-ui-400
  font-normal
`

export const PureLayout: FC<PureProps> = ({
  children,
  contentPad = true,
  hideHead = false,
  hideSide = false,
  Header = <IndexHeader />,
  mainInnerClassName = ``,
}) => {
  const [offscreenMenuShown, setOffscreenMenuShown] = useState(false)

  const closeMenu = useRef((): void => setOffscreenMenuShown(false))
  const openMenu = useRef((): void => setOffscreenMenuShown(true))
  const mobileMenuHelpers = useMemo(
    () => ({
      closeMenu: closeMenu.current,
      openMenu: openMenu.current,
    }),
    [closeMenu, openMenu]
  )

  useChangeBlocker()

  return (
    <MobileMenuContext.Provider value={mobileMenuHelpers}>
      <Wrap className={wrapClassName}>
        <div className={`flex h-screen overflow-hidden`}>
          {!hideSide && (
            <I18nextProvider i18n={i18n}>
              <SidebarOffscreen expanded={offscreenMenuShown} />
              <SidebarStatic />
            </I18nextProvider>
          )}
          <div className={`flex w-0 flex-1 flex-col overflow-hidden`}>
            {!hideHead && cloneElement(Header)}

            <main
              className={`
                relative
                flex
                flex-1
                flex-col
                overflow-y-auto
                focus:outline-none
              `}
            >
              <div
                data-testid="mainInner"
                className={`
                    ${contentPad ? styles.mainSpacing : ``}
                    ${mainInnerClassName}
                    flex
                    flex-1
                    flex-col
                  `}
              >
                {children}
              </div>
            </main>
          </div>
        </div>
      </Wrap>
    </MobileMenuContext.Provider>
  )
}

export default PureLayout
