import {ApolloQueryResult, MutationResult} from '@apollo/client'
import {
  Field,
  Options,
  SelectFieldOptions,
} from '@myadbox/nebula-template-utils'

export interface LanguageValue {
  [key: string]: string
}

export type IntegrationCategoryUnion = `SOCIAL` | `PRINTER` | `SESIMI_SUITE`

export interface Integration {
  id: string
  name: string
  description: LanguageValue
  intro: LanguageValue
  logo?: string
  config: IntegrationConfig
  category: IntegrationCategoryUnion
  isEnabledByDefault?: boolean
  isActive?: boolean
}

export enum ActionType {
  PRINT_ORDER_CREATED = `PRINT_ORDER_CREATED`,
}

export interface IntegrationLog {
  integrationId: string
  action?: ActionType
  data?: Record<string, unknown>
  assetId?: string
}

export type IntegrationLogInput = IntegrationLog

export interface IntegrationConfig {
  fields: IntegrationField[]
}

export interface IntegrationField extends Omit<Field, `type`> {
  options: IntegrationFieldOptions
}

export interface DatasetFieldOptions extends Options {
  selectionCriteria: string[]
}

export interface ModifierFieldOptions extends Options {
  modifier: `percentage` | `fixed`
}

export type IntegrationFieldOptions =
  | Options
  | DatasetFieldOptions
  | SelectFieldOptions
  | ModifierFieldOptions

export interface UseIntegrationsOutput {
  fetchIntegrations(): void
  fetchIntegrationsResults: ApolloQueryResult<{integrations: Integration[]}>
  integrate(input: IntegrationLogInput): void
  integrateResponse: MutationResult<{integrate: IntegrationLog}>
}
