import type {FC, HTMLAttributes, ReactElement, ReactNode} from 'react'
import {cloneElement, createElement, isValidElement} from 'react'

export interface HasConditionalIcon {
  defaultIcon: ReactNode
  activeIcon: FC | ReactNode
}

interface Props extends HTMLAttributes<SVGElement>, HasConditionalIcon {
  current?: boolean
}

export const ConditionalIcon = ({
  current = false,
  defaultIcon,
  activeIcon,
  ...props
}: Props) => {
  const icon = current ? activeIcon : defaultIcon

  let TheIcon: ReactElement | null = null
  if (typeof icon?.[`render`] === `function`) {
    TheIcon = createElement(icon as FC)
  } else if (isValidElement(icon)) {
    TheIcon = icon
  }

  return TheIcon
    ? cloneElement(TheIcon, {
        role: `presentation`,
        ...props,
      })
    : null
}

export default ConditionalIcon
