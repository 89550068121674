import parse from 'date-fns/parse'
import {SupportedLocales, shortLocaleFormats} from '../../utils/dates'

export const isSameDay = (date: Date, start: Date): boolean => {
  const options = {
    weekday: `short` as `short` | `long` | `narrow`,
    year: `numeric` as `numeric` | `2-digit`,
    month: `short` as `short` | `long` | `narrow` | `numeric` | `2-digit`,
    day: `numeric` as `numeric` | `2-digit`,
  }

  return (
    date?.toLocaleString(`en-AU`, options) ===
    start?.toLocaleString(`en-AU`, options)
  )
}

export const isSameTime = (time: Date, start: Date): boolean => {
  const options = {
    hour: `2-digit` as `numeric` | `2-digit`,
    minute: `2-digit` as `numeric` | `2-digit`,
  }
  return (
    time?.toLocaleTimeString([], options) ==
    start?.toLocaleTimeString([], options)
  )
}

export const getIitialStartDateTime = (
  startDateTime: string | Date,
  locale: SupportedLocales,
  newDate: Date,
  showTime: boolean
): Date => {
  return typeof startDateTime === `string`
    ? parse(
        startDateTime,
        shortLocaleFormats[showTime ? `dateAndTime` : `date`][locale],
        newDate
      )
    : startDateTime
}
