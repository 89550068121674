import {SettingsTabComponentName, SettingsViewProps} from '../helpers'
import SchemaList from './SchemaList'
import SchemaView from './SchemaView'

const Schemas = ({viewType, id, page}: SettingsViewProps) => {
  return viewType === `view` ? (
    <SchemaView id={id} page={page} />
  ) : (
    <SchemaList />
  )
}

Schemas.displayName = `Schemas` satisfies SettingsTabComponentName
export default Schemas
